<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card class="elevation-12">
          <v-form
            ref="form"
            v-model="validForm"
            @submit.prevent="requestPasswordReset"
          >
            <v-toolbar color="primary" dark flat>
              <v-toolbar-title class="security-form-title"
                >Solicitar troca de senha
                <div class="logo-container">
                  <img src="../../assets/images/logo.png" />
                </div>
              </v-toolbar-title>
            </v-toolbar>
            <div style="position: relative">
              <ProgressBar></ProgressBar>
            </div>
            <v-card-text>
              <v-text-field
                label="E-mail"
                name="email"
                required
                :rules="[$rules.required]"
                v-model="user.email"
                prepend-icon="mdi-email"
                type="email"
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-btn to="/login">Voltar</v-btn>
              <v-spacer></v-spacer>
              <v-btn
                type="submit"
                color="primary"
                :loading="requestPasswordButton.loading"
                >Solicitar</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import userApi from "@/common/api/user.api";
import toaster from "@/common/util/toaster";
import ProgressBar from "@/components/layout/ProgressBar";

export default {
  name: "RequestPasswordChange",

  components: {
    ProgressBar,
  },

  data() {
    return {
      user: {},
      validForm: true,
      requestPasswordButton: {
        loading: false,
      },
    };
  },

  methods: {
    requestPasswordReset() {
      this.$refs.form.validate();

      if (this.validForm) {
        this.requestPasswordButton.loading = true;

        userApi
          .requestPasswordReset(this.user.email)
          .then(() => {
            this.requestPasswordButton.loading = false;

            toaster.show("Solicitação de senha enviada por e-mail!");
            this.$router.replace("/login");
          })
          .catch(() => (this.requestPasswordButton.loading = false));
      }
    },
  },
};
</script>